import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Manrope } from "@next/font/google";
import { setDefaultOptions } from "date-fns";
import { de } from "date-fns/locale";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { IntlErrorCode, NextIntlProvider } from "next-intl";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ComponentProps, useCallback, useState } from "react";
import ReactModal from "react-modal";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import favIcon from "../public/favicon.png";
import Bold from "../src/components/intl/Bold";
import Green from "../src/components/intl/Green";
import SmallText from "../src/components/intl/SmallText";
import Layout from "../src/components/Layout/Layout";
import AuthContext, { AuthContextState } from "../src/context/AuthContext";
import { ConfirmModalProvider } from "../src/context/ConfirmModalContext";
import { persistor, store } from "../src/redux/store";
import { AppTitle } from "../src/util";
import "../styles/globals.css";
import { HGFPage } from "../types/util";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-toastify/dist/ReactToastify.min.css";

setDefaultOptions({
  locale: de,
});

const manropeFont = Manrope({
  display: "swap",
  subsets: ["latin"],
});

const getMessageFallback: ComponentProps<
  typeof NextIntlProvider
>["getMessageFallback"] = ({ namespace, key, error }) => {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return process.env.NODE_ENV === "development"
      ? `${path} is not yet translated`
      : path;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
};

type MyAppProps = Omit<AppProps, "Component"> & {
  pageProps: {
    messages: IntlMessages;
    session: Session | null | undefined;
  };
  Component: HGFPage;
};
ReactModal.setAppElement("#__next");

config.autoAddCss = false;

const AdminPathRegex = /\/admin\/?/;

export default function MyApp({ Component, pageProps }: MyAppProps) {
  const router = useRouter();

  const [c, setC] = useState<AuthContextState["c"]>({
    logout: "/auth/login",
    login: "/dashboard",
  });

  const updateC = useCallback<AuthContextState["updateCallback"]>(
    (key, value) => {
      setC((c) => ({ ...c, [key]: value }));
    },
    []
  );

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="We are convinced that a future worth living requires effective climate protection today. Together with you, we are saving the climate."
        />
        <title>{AppTitle({})}</title>
        <link rel="shortcut icon" href={favIcon.src} type="image/x-icon" />
        <meta name="theme-color" content="#e0f1f5" />
      </Head>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <SessionProvider session={pageProps.session}>
            <AuthContext.Provider
              value={{
                c: c,
                updateCallback: updateC,
              }}
            >
              <NextIntlProvider
                getMessageFallback={getMessageFallback}
                timeZone="Europe/Berlin"
                messages={pageProps.messages}
                defaultTranslationValues={{
                  green: Green,
                  bold: Bold,
                  small: SmallText,
                }}
              >
                <style jsx global>{`
                  html {
                    font-family: ${manropeFont.style.fontFamily};
                  }
                `}</style>
                <div className={manropeFont.className}>
                  <Layout
                    a={AdminPathRegex.test(router.pathname)}
                    config={Component.Layout}
                  >
                    <ConfirmModalProvider>
                      <Component {...pageProps} />
                    </ConfirmModalProvider>
                  </Layout>
                </div>
                <ToastContainer
                  position={"bottom-right"}
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </NextIntlProvider>
            </AuthContext.Provider>
          </SessionProvider>
        </PersistGate>
      </Provider>
    </>
  );
}
